@tailwind base;
@tailwind components;
@tailwind utilities;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #BADAD8 #E2F4F1;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #E2F4F1;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #BADAD8;
  border-radius: 14px;
  border: 3px solid #E2F4F1;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
   url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.provider-type-icon {
  opacity: 0.35;
}
.provider-type-icon.active {
  opacity: 1;
  filter: invert(50%) sepia(24%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}
svg.active-green path {
  stroke: #08A284;
}
svg.active-green g {
  opacity: 1;
}
svg.active-white path {
  stroke: #FFF;
}
svg.inactive path {
  stroke: #121212;
}

.star-rating {
  display: flex !important;
}

.slick-next, .slick-prev {
  &::before {
    display: none;
  }
}
.slick-track {
  margin-left: unset;
  margin-right: unset;
}

.react-datepicker {
  border: none;
}
.react-datepicker__header.react-datepicker__header--custom {
  padding: 0;
  background: #FFF;
  border: none;
}
.react-datepicker__day {
  width: 44px;
  height: 44px;
}
.react-datepicker__day-name {
  width: 44px;
  color: #969696;
}
.react-datepicker__day--selected {
  color: #FFF;
  background: #08A284;
  border-radius: 100%;
}
.react-datepicker__day--selected:hover {
  background: #08A284;
}
.react-datepicker__input-time-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 8px 12px 0;
}
.react-datepicker-time__caption {
  font-size: 20px;
  font-weight: 500;
}
.react-datepicker-time__input input {
  padding: 1px 8px;
  font-size: 18px;
  text-align: center;
  border: solid 1px #E6E6E6;
  border-radius: 25px;
}
.react-datepicker-time__input input:focus {
  border: solid 1px #08A284;
  outline: none;
}
